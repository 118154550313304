body{
    font-family: 'Varela Round', sans-serif;
    
   
}
body::-webkit-scrollbar{
    display: none;
}
h1{
    border-radius: 15px; 
    background: black;
}
.id-cartHeader{
    border-radius: 15px;;
    background-color: black;
}

.id-headerPageCart{
  text-align: center;
  font-family: 'Rubik', sans-serif;
  font-family: 'Varela Round', sans-serif;
  font-size: 40px;
  font-weight: 100; line-height: 50px; letter-spacing: 1px; padding: 0 0 10px; border-bottom: double #555;
  -webkit-animation: slide-in-blurred-left 1s both;
	        animation: slide-in-blurred-left 1s both;
}
.id-priceContainer, .id-totals{
    font-family: 'Rubik', sans-serif;
}
.id-cartHeader, .id-containerFormHeader, .LkppF {
    background: black !important;
}
.LkppF, .id-containerFormHeader{
    padding-top: 5px;
}
.id-submitBtn{
        background: #0275d8!important;
}
.id-submitBtn:hover{
        transform: scale(1.1);
        border: 1px solid rgba(0,0,0,.15);
}
.id-line-992 > div{
        background: black !important;;
}
.id-line-389{
        padding-top: 0cm !important;;
}
.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.header-in {
	-webkit-animation: header-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: header-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.slide-in-blurred-left {
	-webkit-animation: slide-in-blurred-left 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-left 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    text-align: center;
}
.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}

@media (max-width: 600px ) {
        .id-line-389 > img{   
                width: 550px;           
    }
        .id-line-389{
                padding-top: 0cm !important;;
    }
}
@media (max-width: 500px ) {
    .id-line-389 > img{   
        width: 450px;           
    }
    .id-line-389{
        padding-top: 0cm !important;;
    }
}
@media (max-width: 400px ) {
    .id-line-389 > img{   
        width: 350px;           
    }
    .id-line-389{
        padding-top: 0cm !important;
        
    }
}
@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
@-webkit-keyframes header-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes header-in {
    0% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
@-webkit-keyframes slide-in-blurred-left {
    0% {
      -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
              transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-left {
    0% {
      -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
              transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) scaleY(1) scaleX(1);
              transform: translateX(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
}
@-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
}
  
  

